import Auth from '@aws-amplify/auth';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { signIn } from './authorizeSlice';

export const beginSignUp = createAsyncThunk(
  'signUp/beginSignUp',
  async (userDetails, thunkAPI) => {
    const response = await Auth.signUp({
      username: userDetails.username,
      password: userDetails.password,
      attributes: {
        email: userDetails.email,
        family_name: userDetails.lastName,
        name: userDetails.firstName,
        'custom:mailing_list': userDetails.mailingList ? '1' : '0',
      },
    });
    return {
      username: response.user.username,
      sub: response.userSub,
      password: userDetails.password,
    };
  },
);
export const confirmSignUpCode = createAsyncThunk(
  'signUp/confirmSignUpCode',
  async ({ username, code }, thunkAPI) => {
    await Auth.confirmSignUp(username, code);
    const {
      signUp: { password },
    } = thunkAPI.getState('signUp');
    const signInResult = await thunkAPI.dispatch(
      signIn({ username, password }),
    );
    return signInResult;
  },
);
export const resendConfirmationCode = createAsyncThunk(
  'signUp/resendConfirmationCode',
  async (username, thunkAPI) => {
    const response = await Auth.resendSignUp(username);
    return { sentTo: response.CodeDeliveryDetails.Destination };
  },
);

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState: {
    loading: false,
    error: null,
    username: null,
    password: null,
  },
  reducers: {},
  extraReducers: {
    [beginSignUp.fulfilled]: (state, action) => {
      state.loading = false;
      state.username = action.payload.username;
      state.password = action.payload.password;
    },
    [beginSignUp.pending]: (state, action) => {
      state.loading = true;
    },
    [beginSignUp.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [confirmSignUpCode.pending]: (state) => {
      state.loading = true;
    },
    [confirmSignUpCode.rejected]: (state) => {
      state.loading = false;
    },
    [confirmSignUpCode.fulfilled]: (state) => {
      state.loading = false;
    },
    [resendConfirmationCode.pending]: (state) => {
      state.loading = true;
    },
    [resendConfirmationCode.rejected]: (state) => {
      state.loading = false;
    },
    [resendConfirmationCode.fulfilled]: (state) => {
      state.loading = false;
    },
  },
});
export const selectLoading = (state) => state.signUp.loading;
export const selectError = (state) => state.signUp.error;
export const selectUsername = (state) => state.signUp.username;

export default signUpSlice.reducer;
