import Auth from '@aws-amplify/auth';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { signIn } from './authorizeSlice';

export const sendResetCode = createAsyncThunk(
  'forgotPassword/sendCode',
  async (username, thunkAPI) => {
    const { CodeDeliveryDetails } = await Auth.forgotPassword(username);
    return CodeDeliveryDetails;
  },
);
export const submitResetPassword = createAsyncThunk(
  'forgotPassword/submit',
  async ({ username, code, newPassword }, thunkAPI) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    const signInResult = await thunkAPI.dispatch(
      signIn({ username, password: newPassword }),
    );
    return signInResult;
  },
);

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [sendResetCode.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [sendResetCode.pending]: (state, action) => {
      state.username = action.meta.arg;
      state.loading = true;
    },
    [sendResetCode.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [submitResetPassword.pending]: (state) => {
      state.loading = true;
    },
    [submitResetPassword.rejected]: (state) => {
      state.loading = false;
    },
    [submitResetPassword.fulfilled]: (state) => {
      state.loading = false;
    },
  },
});
export const selectLoading = (state) => state.resetPassword.loading;
export const selectError = (state) => state.resetPassword.error;
export const selectUsername = (state) => state.resetPassword.username;

export default resetPasswordSlice.reducer;
