import React from 'react';
import { Form, Input, FormItem, SubmitButton } from 'formik-antd';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Button, notification } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from '../../components/layout';
import { selectLoading, sendResetCode } from './resetPasswordSlice';

const forgotSchema = Yup.object().shape({
  email: Yup.string().min(1).email('Invalid email').required('Email Required'),
});
export function ResetPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  return (
    <Formik
      validationSchema={forgotSchema}
      initialValues={{ email: '' }}
      onSubmit={async (values) => {
        const result = await dispatch(sendResetCode(values.email));
        if (result.error) {
          notification.error(result.error);
        } else {
          history.push('/reset-password/code');
        }
      }}
    >
      <Form>
        <MainLayout
          title="Reset Password"
          cardActions={[
            <SubmitButton loading={loading} disabled={false} size="large">
              Send Password Email
            </SubmitButton>,
          ]}
          extras={
            <Button
              type="primary"
              ghost
              size="large"
              onClick={() => history.push('/login')}
            >
              Login
            </Button>
          }
        >
          <FormItem name="email" required>
            <Input
              size="large"
              autoComplete="email"
              prefix={
                <MailOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="email"
              placeholder="Email"
            />
          </FormItem>
        </MainLayout>
      </Form>
    </Formik>
  );
}
export default ResetPassword;
