import React, { useEffect } from 'react';
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Hub } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import Router from './features';
import {
  incomingAuthRequest,
  loadUser,
  selectWindowUrl,
} from './features/auth/authorizeSlice';

function App() {
  const dispatch = useDispatch();
  const windowUrl = useSelector(selectWindowUrl);
  useEffect(() => {
    dispatch(loadUser());
  });
  useEffect(() => {
    if (windowUrl) {
      window.location.assign(windowUrl);
    }
  }, [windowUrl]);
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'customOAuthState':
          dispatch(incomingAuthRequest({ redirectUri: data }));
          break;
        case 'signIn_failure':
          console.log(data);
          notification.error({
            message: 'Login Error',
            description: data.message.replace(/\+/g, ' '),
          });
          break;
        default:
          break;
      }
    });
    return undefined;
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
