import React from 'react';
import { Button, Typography, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import { MainLayout } from '../../components/layout';
import {
  completeAuthRequest,
  selectRedirect,
  clearUser,
  selectAuthLoading,
  selectUser,
} from './authorizeSlice';

export function LoginSuccess() {
  const dispatch = useDispatch();
  const redirect = new URL('', useSelector(selectRedirect));
  const loading = useSelector(selectAuthLoading);
  console.log('loading', loading);
  const user = useSelector(selectUser);
  const history = useHistory();
  return (
    <MainLayout
      title="Sign In"
      cardLoading={loading}
      cardActions={[
        <Button
          type="primary"
          size="large"
          onClick={() => dispatch(completeAuthRequest())}
        >
          I trust it
        </Button>,
      ]}
      extras={
        <Button
          size="large"
          type="primary"
          ghost
          onClick={() =>
            Auth.signOut().then(() => {
              dispatch(clearUser());
              history.push('/login');
            })
          }
        >
          Sign Out
        </Button>
      }
    >
      <Alert
        message="Warning"
        description={
          <Typography>
            <Typography.Paragraph>
              Ensure you trust the application at{' '}
              <strong>{redirect.host}</strong> before granting it access to your
              account.
            </Typography.Paragraph>
          </Typography>
        }
        type="warning"
        showIcon
      />
      {user && (
        <div style={{ textAlign: 'center', marginTop: '24px' }}>
          Sign in as <strong>{user.attributes.email}</strong>
        </div>
      )}
    </MainLayout>
  );
}
export default LoginSuccess;
