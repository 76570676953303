import { configureStore } from '@reduxjs/toolkit';
import signUpReducer from '../features/auth/signupSlice';
import authorizeReducer from '../features/auth/authorizeSlice';
import resetPasswordReducer from '../features/auth/resetPasswordSlice';

export default configureStore({
  reducer: {
    signUp: signUpReducer,
    authorize: authorizeReducer,
    resetPassword: resetPasswordReducer,
  },
});
