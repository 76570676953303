import React from 'react';
import { Form, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { Button, notification } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MainLayout } from '../../components/layout';
import { password, username as usernameValidation } from './validations';
import {
  sendResetCode,
  submitResetPassword,
  selectLoading,
  selectUsername,
} from './resetPasswordSlice';

const codeSchema = Yup.object().shape({
  username: usernameValidation.required(),
  authCode: Yup.string().label('Auth code').required('Required'),
  newPassword: password.required(),
  passwordConfirm: Yup.string()
    .label('Password confirmation')
    .required('Required')
    .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
});

export function ResetPasswordCode() {
  const loading = useSelector(selectLoading);
  const username = useSelector(selectUsername);
  const history = useHistory();
  const dispatch = useDispatch();
  if (!username) {
    history.push('/reset-password');
  }
  return (
    <Formik
      onSubmit={async (values) => {
        const result = await dispatch(
          submitResetPassword({
            username: values.username,
            code: values.authCode,
            newPassword: values.newPassword,
          }),
        );
        if (result.error) {
          notification.error(result.error);
        } else {
          history.push('/login/success');
        }
      }}
      initialValues={{
        username,
        authCode: '',
      }}
      validationSchema={codeSchema}
    >
      <Form>
        <MainLayout
          title="Authorization Code"
          cardActions={[
            <SubmitButton loading={loading} disabled={false} size="large">
              Submit
            </SubmitButton>,
            <Button
              size="large"
              loading={loading}
              onClick={async () => {
                const result = await dispatch(sendResetCode(username));
                if (result.error) {
                  notification.error(result.error);
                } else {
                  notification.info({
                    message: `Code resent to ${result.payload.Destination}`,
                  });
                }
              }}
            >
              Resend Code
            </Button>,
          ]}
          extras={
            <Button
              ghost
              type="primary"
              size="large"
              onClick={() => history.push('/login')}
            >
              Login
            </Button>
          }
        >
          <Input hidden value={username} name="username" />
          <Form.Item name="authCode">
            <Input
              label="Auth code"
              size="large"
              prefix={
                <LockOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="authCode"
              placeholder="Authentication Code"
            />
          </Form.Item>
          <Form.Item showValidateSuccess name="newPassword" required>
            <Input.Password
              size="large"
              autoComplete="new-password"
              prefix={
                <LockOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="newPassword"
              placeholder="New Password"
            />
          </Form.Item>
          <Form.Item showValidateSuccess name="passwordConfirm" required>
            <Input.Password
              size="large"
              prefix={
                <LockOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="passwordConfirm"
              placeholder="Confirm password"
            />
          </Form.Item>
        </MainLayout>
      </Form>
    </Formik>
  );
}

export default ResetPasswordCode;
