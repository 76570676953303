import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Auth from '@aws-amplify/auth';

function parseUserObj(user) {
  const { accessToken, idToken, refreshToken } = user.signInUserSession;
  return {
    accessToken: accessToken.jwtToken,
    idToken: idToken.jwtToken,
    refreshToken: refreshToken.token,
    username: user.username,
    attributes: user.attributes,
  };
}
export const signIn = createAsyncThunk(
  'signIn',
  async ({ username, password }) => {
    const response = await Auth.signIn(username, password);
    return parseUserObj(response);
  },
);
export const loadUser = createAsyncThunk('loadUser', async () => {
  const user = await Auth.currentAuthenticatedUser();
  return parseUserObj(user);
});
export const authorizeSlice = createSlice({
  name: 'authorize',
  initialState: {
    redirect: process.env.REACT_APP_FALLBACK_CALLBACK,
    redirectArg: null,
    windowUrl: null,
    user: null,
    loading: false,
    error: null,
  },
  reducers: {
    incomingAuthRequest(state, action) {
      state.redirect = action.payload.redirectUri;
      state.redirectArg = action.payload.state;
    },
    completeAuthRequest(state, action) {
      console.log(state, action);
      const newURL = new URL('', state.redirect);
      const {
        user: { idToken, accessToken, refreshToken },
      } = state;
      const params = new URLSearchParams({
        idToken,
        accessToken,
        refreshToken,
        state: state.redirectArg || undefined,
      });
      newURL.search = params;
      state.windowUrl = newURL.toString();
      state.loading = true;
    },
    updateUser(state, action) {
      state.user = action.payload;
    },
    clearUser(state, action) {
      state.user = null;
    },
  },
  extraReducers: {
    [signIn.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    [signIn.pending]: (state, action) => {
      state.user = null;
      state.loading = true;
    },
    [signIn.rejected]: (state, action) => {
      state.user = null;
      state.loading = false;
      state.error = action.error;
    },
    [loadUser.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    [loadUser.rejected]: (state, action) => {
      state.user = null;
      state.loading = false;
    },
    [loadUser.pending]: (state, action) => {
      state.user = null;
      state.loading = true;
    },
  },
});
export const {
  incomingAuthRequest,
  completeAuthRequest,
  updateUser,
  clearUser,
} = authorizeSlice.actions;
export const selectRedirect = ({ authorize }) => authorize.redirect;
export const selectRedirectArg = ({ authorize }) => authorize.redirectArg;
export const selectWindowUrl = ({ authorize }) => authorize.windowUrl;
export const selectUser = ({ authorize }) => authorize.user;
export const selectAuthLoading = ({ authorize }) => authorize.loading;

export default authorizeSlice.reducer;
