import React from 'react';
import { Form, Input, FormItem, SubmitButton } from 'formik-antd';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { Space, Row, Divider, Button, Alert, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '@aws-amplify/auth';
import GoogleButton from 'react-google-button';
import * as Yup from 'yup';
import { MainLayout } from '../../components/layout';
import { signIn, selectRedirect, selectAuthLoading } from './authorizeSlice';
import { username, password } from './validations';

const loginSchema = Yup.object().shape({
  username: username.required(),
  password: password.required(),
});

export function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const redirect = new URL(useSelector(selectRedirect));
  return (
    <MainLayout
      title="Login"
      cardLoading={loading}
      extras={
        <Button
          type="primary"
          ghost
          size="large"
          onClick={() => history.push('/sign-up')}
        >
          Sign Up
        </Button>
      }
    >
      <Row justify="center" style={{ display: 'none' }}>
        <Space direction="vertical" align="center">
          <Alert
            message="Warning"
            description={
              <Typography>
                <Typography.Paragraph>
                  Ensure you trust the application at{' '}
                  <strong>{redirect.host}</strong> before granting it access to
                  your account.
                </Typography.Paragraph>
              </Typography>
            }
            type="warning"
            showIcon
          />
          <GoogleButton
            disabled={loading}
            onClick={async () => {
              Auth.federatedSignIn({
                provider: 'Google',
                customState: redirect.toString(),
              });
            }}
          >
            Google Login
          </GoogleButton>
        </Space>
        <Divider>Or Username/Email</Divider>
      </Row>
      <Row justify="center">
        <Formik
          initialValues={{ username: '', password: '' }}
          onSubmit={async (values) => {
            const castValues = loginSchema.cast(values);
            const { error } = await dispatch(signIn({ ...castValues }));
            if (error) {
              if (error.name === 'PasswordResetRequiredException') {
                history.push('/forgot-password');
              }
            }
          }}
          validationSchema={loginSchema}
        >
          <Form size="large">
            <Space direction="vertical">
              <FormItem name="username" required>
                <Input
                  label="Username"
                  autoComplete="email username"
                  size="large"
                  prefix={
                    <UserOutlined
                      style={{
                        color: 'rgba(0,0,0,.25)',
                      }}
                    />
                  }
                  name="username"
                  placeholder="Username or Email"
                />
              </FormItem>
              <FormItem name="password" required>
                <Input.Password
                  autoComplete="password"
                  prefix={
                    <LockOutlined
                      style={{
                        color: 'rgba(0,0,0,.25)',
                      }}
                    />
                  }
                  name="password"
                  placeholder="Password"
                />
              </FormItem>
              <SubmitButton loading={loading} block>
                Login
              </SubmitButton>
            </Space>
          </Form>
        </Formik>
      </Row>
      <Row style={{ marginTop: '16px' }} justify="center">
        <Link to="/reset-password" type="link">
          Forgot Password?
        </Link>
      </Row>
    </MainLayout>
  );
}
export default Login;
