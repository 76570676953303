import React from 'react';
import { Form, Input, SubmitButton, Checkbox } from 'formik-antd';
import { Formik } from 'formik';
import { Button, notification } from 'antd';
import {
  LockOutlined,
  UserOutlined,
  MailOutlined,
  IdcardOutlined,
} from '@ant-design/icons';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MainLayout } from '../../components/layout';
import { beginSignUp, selectLoading } from './signupSlice';
import { password, username } from './validations';

const signUpSchema = Yup.object().shape({
  username: username.required(),
  firstName: Yup.string()
    .label('First Name')
    .min(1, 'Too Short!')
    .max(128, 'Too Long!')
    .required(),
  lastName: Yup.string()
    .label('Last Name')
    .min(1, 'Too Short!')
    .max(128, 'Too Long!'),
  email: Yup.string()
    .label('Email')
    .lowercase()
    .email('Invalid email')
    .required(),
  password: password.required(),
  passwordConfirm: Yup.string()
    .label('Password confirmation')
    .required('Required')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
  mailingList: Yup.bool().label('Mailing List'),
});

export function SignUp() {
  const history = useHistory();
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();
  return (
    <Formik
      onSubmit={async (values) => {
        const castValues = signUpSchema.cast(values);
        const result = await dispatch(beginSignUp(castValues));
        if (result.error) {
          notification.error(result.error);
        } else {
          history.push(`/sign-up/auth-code`);
        }
      }}
      initialValues={{
        email: '',
        username: '',
        password: '',
        passwordConfirm: '',
        mailingList: true,
      }}
      validationSchema={signUpSchema}
    >
      <Form>
        <MainLayout
          title="Sign Up"
          back
          cardActions={[
            <SubmitButton loading={loading} size="large">
              Register
            </SubmitButton>,
          ]}
          extras={
            <Button
              ghost
              type="primary"
              size="large"
              onClick={() => history.push('/')}
            >
              Login
            </Button>
          }
        >
          <Form.Item showValidateSuccess name="email" required>
            <Input
              label="Email"
              size="large"
              prefix={
                <MailOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="email"
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item showValidateSuccess name="username" required>
            <Input
              label="Username"
              size="large"
              prefix={
                <UserOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="username"
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item showValidateSuccess name="firstName" required>
            <Input
              label="First Name"
              size="large"
              prefix={
                <IdcardOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="firstName"
              placeholder="First Name"
            />
          </Form.Item>
          <Form.Item showValidateSuccess name="lastName" required>
            <Input
              label="Last Name"
              size="large"
              prefix={
                <IdcardOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="lastName"
              placeholder="Last Name"
            />
          </Form.Item>

          <Form.Item showValidateSuccess name="password" required>
            <Input.Password
              size="large"
              prefix={
                <LockOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item showValidateSuccess name="passwordConfirm" required>
            <Input.Password
              size="large"
              prefix={
                <LockOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="passwordConfirm"
              placeholder="Confirm password"
            />
          </Form.Item>
          <Form.Item name="mailingList" required>
            <Checkbox
              size="large"
              prefix={
                <LockOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="mailingList"
            >
              Join Mailing List
            </Checkbox>
          </Form.Item>
        </MainLayout>
      </Form>
    </Formik>
  );
}
export default SignUp;
