import React from 'react';
import { Form, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import { Button, notification } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MainLayout } from '../../components/layout';
import {
  confirmSignUpCode,
  resendConfirmationCode,
  selectLoading,
  selectUsername,
} from './signupSlice';

const codeSchema = Yup.object().shape({
  username: Yup.string().required(),
  authCode: Yup.string().label('Auth code').required('Required'),
});

export function AuthCode() {
  const loading = useSelector(selectLoading);
  const username = useSelector(selectUsername);
  const history = useHistory();
  const dispatch = useDispatch();
  if (!username) {
    history.push('/sign-up');
  }
  return (
    <Formik
      onSubmit={async (values) => {
        const result = await dispatch(
          confirmSignUpCode({
            username: values.username,
            code: values.authCode,
          }),
        );
        if (result.error) {
          notification.error(result.error);
        } else {
          history.push('/login/success');
        }
      }}
      initialValues={{
        username,
        authCode: '',
      }}
      validationSchema={codeSchema}
    >
      <Form>
        <MainLayout
          title="Authorization Code"
          cardActions={[
            <SubmitButton loading={loading} disabled={false} size="large">
              Submit
            </SubmitButton>,
            <Button
              size="large"
              loading={loading}
              onClick={async () => {
                const result = await dispatch(resendConfirmationCode(username));
                if (result.error) {
                  notification.error(result.error);
                } else {
                  notification.info({
                    message: `Code resent to ${result.payload.sentTo}`,
                  });
                }
              }}
            >
              Resend Code
            </Button>,
          ]}
          extras={
            <Button
              ghost
              type="primary"
              size="large"
              onClick={() => history.push('/login')}
            >
              Login
            </Button>
          }
        >
          <Input hidden value={username} name="username" />
          <Form.Item name="authCode">
            <Input
              label="Auth code"
              size="large"
              prefix={
                <LockOutlined
                  style={{
                    color: 'rgba(0,0,0,.25)',
                  }}
                />
              }
              name="authCode"
              placeholder="Authentication Code"
            />
          </Form.Item>
        </MainLayout>
      </Form>
    </Formik>
  );
}

export default AuthCode;
