import React, { useEffect } from 'react';

import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { Login } from './auth/Login';
import { ResetPassword } from './auth/ResetPassword';
import { ResetPasswordCode } from './auth/ResetPasswordCode';

import { SignUp } from './auth/SignUp';
import { AuthCode } from './auth/AuthCode';
import { LoginSuccess } from './auth/LoginSuccess';
import { Logout } from './auth/Logout';
import { incomingAuthRequest, selectUser } from './auth/authorizeSlice';

function Router() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(selectUser);
  const history = useHistory();
  useEffect(() => {
    if (user) {
      history.push('/login/success');
    }
  }, [history, user]);
  useEffect(() => {
    if (location.pathname === '/authorize') {
      const { redirect_uri: redirectUri, state } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      dispatch(incomingAuthRequest({ redirectUri, state }));
    }
  }, [dispatch, location]);
  return (
    <Switch>
      <Route path="/logout" component={Logout} />
      <Route path="/reset-password/code" component={ResetPasswordCode} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/sign-up/auth-code" component={AuthCode} />
      <Route path="/sign-up" component={SignUp} />
      <Route path="/login/success" component={LoginSuccess} />
      <Route path="/" component={Login} />
    </Switch>
  );
}

export default Router;
