import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import Auth from '@aws-amplify/auth';
import { Col, Row, Spin } from 'antd';

export function Logout() {
  const location = useLocation();
  const { logout_uri: logoutUri } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  useEffect(() => {
    Auth.signOut().then(() => window.location.replace(logoutUri));
  }, [logoutUri]);
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: '100vh' }}
    >
      <Col>
        <Spin size="large" />
      </Col>
    </Row>
  );
}

export default Logout;
