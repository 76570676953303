import React from 'react';
import { Row, Layout, Image, Col, Typography, Card } from 'antd';
import PropTypes from 'prop-types';
import logo from '../logo.svg';

export function MainLayout(props) {
  const { children, extras, title, cardActions, cardLoading } = props;
  return (
    <Layout style={{ height: '100vh' }}>
      <Layout.Header style={{ backgroundColor: 'white' }}>
        <Row justify="space-between" align="middle">
          <Col push={1}>
            <Image preview={false} width="200px" src={logo} />
          </Col>
          <Col pull={1}>{extras}</Col>
        </Row>
      </Layout.Header>

      <Layout.Content>
        <Row justify="center" style={{ padding: '50px 0' }}>
          <Col xs={23} sm={18} md={12} lg={12} xl={10}>
            <Card
              loading={cardLoading}
              title={
                <Typography>
                  <Typography.Title
                    strong
                    style={{ textAlign: 'center', margin: 'unset' }}
                  >
                    {title}
                  </Typography.Title>
                </Typography>
              }
              actions={cardActions}
            >
              {children}
            </Card>
          </Col>
        </Row>
      </Layout.Content>
      <Layout.Footer style={{ textAlign: 'center' }}>
        Nom Nom Data Inc ©{new Date().getFullYear()}
      </Layout.Footer>
    </Layout>
  );
}
MainLayout.defaultProps = {
  extras: undefined,
  cardActions: [],
  cardLoading: false,
};
MainLayout.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  extras: PropTypes.element,
  cardActions: PropTypes.arrayOf(PropTypes.element),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  cardLoading: PropTypes.bool,
};
export default MainLayout;
